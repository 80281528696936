@import-normalize;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #505b6b;
  font-family: 'Open Sans', Arial, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  text-decoration: none;
}
