.menu-content {
  top: 72px !important;
  padding-top: 8px;
}

.scrollbar-container {
  font-size: 16px;
  line-height: 22px;
}
.scrollbar-container label {
  transition: background-color 0.2s, color 0.2s;
}
.scrollbar-container > ul > li > label {
  color: #081e5e;
  font-weight: 600;
}
.scrollbar-container .operation-type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  height: 18px;
}

.api-content h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
}

.api-content h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

.api-content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
