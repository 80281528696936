.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  height: 104px;
  background: linear-gradient(
    to right,
    rgba(31, 55, 128, 0.8) 0%,
    #081e5e 100%
  );
}

.footer > nav {
  display: flex;
}

.footer > nav > a {
  margin: 0 8px;
  color: #c4cbd7;
  transition: color 0.2s;
}
.footer > nav > a:hover {
  margin: 0 8px;
  color: #24b7ff;
}

.footer > nav > span {
  margin: 0 8px;
  color: #c4cbd7;
}
