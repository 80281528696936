.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 72px;
  background-color: #f5f9ff;
}
.shadow {
  box-shadow: 0px 2px 4px 0px rgba(8, 31, 94, 0.15);
}

.logo {
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 260px;
  font-weight: 600;
  font-size: 24px;
}
.logo > img {
  margin-right: 16px;
  width: 32px;
  height: 32px;
}

.nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav > a {
  display: flex;
  align-items: center;
  margin: 0 24px;
  padding-top: 4px;
  height: 100%;
  border-bottom: 4px solid transparent;
  color: #505b6b;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  transition: color 0.2s;
}
.nav > a:hover {
  color: #24b7ff;
}
.nav > a.active {
  border-color: #24b7ff;
  color: #24b7ff;
}
